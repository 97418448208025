export const toastErrorMessage = {
  429: "Too many requests! Please try after some time.",
};

const planRequestMap = {
  pause_request: "pause",
  pause_request_accepted: "accepted",
  pause_request_rejected: "rejected",
};

export const notificationMessage = {
  flareup: "has reported a flare up",
  thought_exercise: "has submitted their thought worksheet",
  call_notification: "You have an upcoming call with ",
  due_undue: "Attention! ",
  diet: {
    current_day: " does not have a diet plan for today",
    two_day: " meal plan is ending in 2 days! Please check their diet plan",
    next_week:
      " does not have next week’s meal plan! Please check their diet plan",
  },
};

export const getNotificationMessage = ({
  patientName,
  type,
  day,
  diet,
  orderId,
  alertType,
}) => {
  const message = {
    flareup: (
      <p>
        <span className="font-bold">{patientName}</span>{" "}
        {notificationMessage[type]}
      </p>
    ),
    thought_exercise: (
      <p>
        <span className="font-bold">{patientName}</span>{" "}
        {notificationMessage[type]}
      </p>
    ),
    call_notification: (
      <p>
        {notificationMessage[type]}
        <span className="font-bold">{patientName}</span> in 10 minutes
      </p>
    ),
    // due_undue: <p>{notificationMessage[type]}<span className="font-bold">{`${patientName}'s`}</span> {day > 0 ? `trial plan is ending in ${Math.abs(day)} days` :  day === 0 ? `trial plan is ending today` : `extension is overdue since ${Math.abs(day)} days`}</p>,
    // diet: <p><span className="font-bold">{patientName}</span>{notificationMessage?.[type]?.[diet]}</p>,
    pause_request: (
      <p>
        <span className="font-bold">{orderId}</span> has requested a{" "}
        {planRequestMap[type]} in their plan
      </p>
    ),
    pause_request_accepted: (
      <p>
        <span className="font-bold">Pause Request</span> has been{" "}
        {planRequestMap[type]} for{" "}
        <span className="font-bold">{patientName}</span>
      </p>
    ),
    pause_request_rejected: (
      <p>
        <span className="font-bold">Pause Request</span> has been{" "}
        {planRequestMap[type]} for{" "}
        <span className="font-bold">{patientName}</span>
      </p>
    ),
    due_undue: (
      <p>
        {notificationMessage[type]}
        <span className="font-bold">{`${patientName}'s`}</span>{" "}
        {day > 0
          ? `trial plan is ending in ${Math.abs(day)} days`
          : day === 0
          ? `trial plan is ending today`
          : `extension is overdue since ${Math.abs(day)} days`}
      </p>
    ),
    diet: (
      <p>
        <span className="font-bold">{patientName}</span>
        {notificationMessage?.[type]?.[diet]}
      </p>
    ),
    upi_mandate_fail: (
      <p>
        <span className="font-bold">{patientName}</span> has revoked their UPI
        Mandate
      </p>
    ),
  };

  return message[type] || "";
};
